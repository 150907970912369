<template>
  <v-app>
    <v-main class="grey lighten-3">
      <div class="app_bar">
        <v-container>
          <v-row justify="center">
            <v-col class="py-2" xl="8" lg="10" mb="12" sm="12">
              <v-container class="py-0 fill-height">
                <v-avatar rounded="lg" color="blue darken-3 white--text" size="44" class="mr-10"><b>ID</b></v-avatar>

                <v-spacer></v-spacer>
                <v-btn  text @click="logout">Выход</v-btn>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container class="py-7">
        <v-row justify="center">
          <v-col xl="8" lg="10" mb="12" sm="12">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    mounted() {
      
    },
    computed: {
      selectedLanguage() {
        return this.$store.getters.getLanguage;
      }
    },
    data: () => ({
      languages: {
        'kk': 'Қазақша',
        'ru': 'Русский'
      }
    }),
    methods: {
      changeLanguage(lang) {
        this.$store.commit('setLanguage', lang);
        location.reload();
      },
      logout() {
        this.$store.dispatch('logout');
      }
    }
  }
</script>

<style>
  html {
    font-size: 17px;
  }
  body {
    font-family: -apple-system, system-ui, Helvetica Neue, Roboto, sans-serif !important;
  }
  .v-application {
    font-family: -apple-system, system-ui, Helvetica Neue, Roboto, sans-serif !important;
  }
  .app_bar {
    background-color: #fff;
    box-shadow: 0 0 4px rgb(0 0 0 / 8%);
  }
</style>